<template>
<!-- -------------------------------------这是问卷H5问卷填写页面 -------------------------------------------------------------->
  <div class="home">
      <!-- PC端页面 -->
      <div v-if="isPc" v-loading="loading">
          <el-form ref="submitObject" :model="submitObject" class="form">
                <!-- 问卷标题头 -->
                <h3 class="title">{{pagesTitle}}</h3>
                <p style="text-align:left;text-indent:50px">{{pagesMessage}}</p>
                <!-- 问卷内容主体 -->
                <el-form-item v-for="(item,index) in numberList" :key="item.id" :label="(index+1)+'、'+item.title" :prop='item.id'>
                    <!-- 单选题 -->
                    <div v-if="item.type == 'Single'">
                        <el-radio-group v-model="submitObject[item.id]">
                            <el-radio v-for='singleItem in item.children' :key="singleItem.id" :label="singleItem.id">
                                {{singleItem.title}}
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <!-- 多选题 -->
                    <div v-if="item.type == 'Multi'">
                        <el-checkbox-group v-model="submitObject[item.id]">
                            <el-checkbox v-for='multiItem in item.children' :key="multiItem.id" :label="multiItem.id">
                                {{multiItem.title}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <!-- 下拉题 -->
                    <div v-if="item.type == 'Select'">
                        <el-select v-model="submitObject[item.id]" placeholder="请选择">
                            <el-option
                            v-for="selectItem in item.children"
                            :key="selectItem.id"
                            :label="selectItem.title"
                            :value="selectItem.id">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- 级联题 hover触发型号-->
                    <div v-if="item.type == 'Cascader'">
                        <el-cascader
                            v-model="submitObject[item.id]"
                            :options="item.dataSource"
                            :props="{ expandTrigger: 'hover' }"
                            @change="cascaderChange">
                        </el-cascader>
                    </div>
                    <!-- 填空题 -->
                    <div v-if="item.type == 'FillBlank'">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="submitObject[item.id]">
                        </el-input>
                    </div>
                    <!-- 多项填空题 -->
                    <div v-if="item.type == 'MultipleBlank'">
                        <div v-for="multipleBlankItem in item.children" :key="multipleBlankItem.id" style="margin:24px">
                            <span style="width:10%">{{multipleBlankItem.title}}:</span>
                            <el-input
                                style="width:90%"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="submitObject[item.id][multipleBlankItem.id]">
                            </el-input>
                        </div>
                    </div>
                    <!-- 员工服务评价题 -->
                    <div v-if="item.type == 'Score'">
                        <el-rate
                        v-model="submitObject[item.id]"
                        :colors="colors">
                        </el-rate>
                    </div>
                    <!-- 上传文件图片 -->
                    <div v-if="item.type == 'Upload'">
                        <el-upload
                            :action="pagesUrl+'/api/public/upload'"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :on-success ='upSuccess'>
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="uploadDialog">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </div>
                </el-form-item>
                <div style="width:100%">
                    <el-button type="primary" @click="onSubmit()" class="wancheng">提交</el-button>
                </div>
          </el-form>
      </div>
      
      <!-- 移动端页面 -->
      <div class="imgBox" v-if="!isPc" v-loading="loading">
          <el-form ref="formName" v-model="submitObject" class="mobileform">
                <!-- 问卷标题头 -->
                <el-form-item>
                    <p style="text-align:center">服务评价</p>
                </el-form-item>
                <!-- 问卷内容主体 -->
                <el-form-item v-for="(item,index) in numberList" :key="item.id" :label="(index+1)+'、'+item.title">
                    <!-- 单选题 -->
                    <div v-if="item.type == 'Single'">
                        <el-radio-group v-model="submitObject[item.id]">
                            <el-radio v-for='singleItem in item.children' :key="singleItem.id" :label="singleItem.id">
                                {{singleItem.title}}
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <!-- 多选题 -->
                    <div v-if="item.type == 'Multi'">
                        <el-checkbox-group v-model="submitObject[item.id]">
                            <el-checkbox v-for='multiItem in item.children' :key="multiItem.id" :label="multiItem.id">
                                {{multiItem.title}}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <!-- 下拉题 -->
                    <div v-if="item.type == 'Select'">
                        <el-select v-model="submitObject[item.id]" placeholder="请选择">
                            <el-option
                            v-for="selectItem in item.children"
                            :key="selectItem.id"
                            :label="selectItem.title"
                            :value="selectItem.id">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- 级联题 hover触发型号-->
                    <div v-if="item.type == 'Cascader'">
                        <el-cascader
                            v-model="submitObject[item.id]"
                            :options="item.dataSource"
                            :props="{ expandTrigger: 'hover' }"
                            @change="cascaderChange">
                        </el-cascader>
                    </div>
                    <!-- 填空题 -->
                    <div v-if="item.type == 'FillBlank'">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="submitObject[item.id]">
                        </el-input>
                    </div>
                    <!-- 多项填空题 -->
                    <div v-if="item.type == 'MultipleBlank'">
                        <div v-for="multipleBlankItem in item.children" :key="multipleBlankItem.id" style="margin:24px">
                            <span style="width:10%">{{multipleBlankItem.title}}:</span>
                            <el-input
                                style="width:90%"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="submitObject[item.id][multipleBlankItem.id]">
                            </el-input>
                        </div>
                    </div>
                    <!-- 员工服务评价题 -->
                    <div v-if="item.type == 'Score'">
                        <el-rate
                        v-model="submitObject[item.id]"
                        :colors="colors">
                        </el-rate>
                    </div>
                    <!-- 上传文件图片 -->
                    <div v-if="item.type == 'Upload'">
                        <el-upload
                            :action="pagesUrl+'/api/public/upload'"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :on-success ='upSuccess'>
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="uploadDialog">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </div>
                </el-form-item>
                <div style="width:100%;display:flex">
                    <el-button type="primary" @click="onSubmit" class="wancheng">完成</el-button>
                </div>
            </el-form>
      </div>
  </div>
</template>

<script>
import axios from "axios";
// 引入微信SDK
import wx from 'weixin-js-sdk';
export default {
  data() {
    return {
      loading:false,
      isPc: false,
      submitObject1: {
        single: "", //单选
        multi: [], //多选
        select: "", //下拉
        cascader: [], //级联
        fillBlank: "", //填空
        multipleBlank: [], //多项填空
        score: 0, //评分
        upload: "", //上传
      },
      submitObject: {},
      checkboxList: [], //多选
      submitForm: {}, //提交问卷传参变量
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"], //评分UI颜色变化
      uploadDialog: false,
      uploadDialogUrl: "",
      pagesUrl: "https://wechat.lirimall.com:1991",
      shortId: "",
      userId: "",
      numberList: [],
      dialogImageUrl: "",
      upImage: {
        contentType: "",
        id: "",
        originalName: "",
      },
      attachment: [],
      endTime: "",
      startTime: "",
      pagesTitle:'服务评价',
      pagesMessage:'',
      code:'',//用户微信识别code
    };
  },
  components: {},
  created() {
    let hrefUrl = window.location.href;
    console.log(hrefUrl);
    let queryParam = hrefUrl.split("?")[1];
    let tempary = queryParam.split("&");
    let queryObject = {};
    for (let i = 0; i < tempary.length; i++) {
      queryObject[tempary[i].split("=")[0]] = tempary[i].split("=")[1];
    }
    console.log(queryObject);
    this.shortId = queryObject.shortId;
    this.userId = queryObject.userId;
    this.userId = decodeURI(this.userId)
    if(queryObject.code){
      this.code = queryObject.code
    }
    console.log(this.userId)
  },
  mounted() {
    this.startTime = Date.now();
    this.isMobile();
    this.getPages();
  },
  methods: {
    isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isPc = false;
        console.log("移动端打开");
      } else {
        this.isPc = true;
        console.log("PC端打开");
      }
    },
    toLirigo() {
      location.href = "weixin://dl/business/?t=sBQHWPbT93h";
    },
    // UI交互change-------------------------------start----------------------------------
    // 级联
    cascaderChange() {
      console.log();
    },
    // 上传upload
    // on-preview
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file);
    },
    // om-remove
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    sureButton(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log("成功")
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    // UI交互change-------------------------------end------------------------------------
    // 接口————获取问卷
    getPages() {
      axios({
        method: "GET",
        url: this.pagesUrl + "/api/public/loadProject?shortId=" + this.shortId,
      })
        .then((res) => {
          console.log(res);
          let tempary = [];
          // this.pagesTitle = res.data.data.survey.title
          this.pagesMessage = res.data.data.survey.description
          // this.numberList = res.data.data.survey.children
          res.data.data.survey.children.filter((item) => {
            if (item.title) {
              tempary.push(item);
            }
          });
          console.log(tempary);
          this.numberList = tempary;
          this.numberList.filter((item) => {
            if (item.type == "Multi" || item.type == "Cascader") {
              this.$set(this.submitObject, item.id, []);
            } else if (item.type == "Score") {
              this.$set(this.submitObject, item.id, 0);
            } else if (item.type == "MultipleBlank" || item.type == "Single") {
              this.$set(this.submitObject, item.id, {});
              item.children.filter((itemChild) => {
                this.$set(this.submitObject[item.id], itemChild.id, "");
              });
            } else {
              this.$set(this.submitObject, item.id, "");
            }
          });
          console.log(this.submitObject);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 接口————提交问卷
    onSubmit() {
      this.loading = true
      //手动校验必填<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    //   let requirenumber = 0
    //   for(k in this.submitObject1){
    //       this.numberList.filter((item){
    //           if(item.id == k){
    //               if(this.submitObject1[k]=='' && (item.type == 'Single' || item.type == 'Single' || item.type == 'Single' || item.type == 'Single' || item.type == 'Single')){

    //               }
    //           }
    //       })
    //   }
      //手动校验必填<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<    
      this.endTime = Date.now();
      console.log(this.submitObject);
      //转成入参格式----start<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<   
      let firstLevelKeys = Object.keys(this.submitObject); //得到所有的keys
      console.log(firstLevelKeys);
      let submitObject = {};
      firstLevelKeys.filter((item) => {
        // this.$set(submitObject,item,this.submitObject[item])
        this.numberList.filter((ele) => {
          if (item == ele.id) {
            console.log(item, ele.type);
            this.$set(submitObject, item, {});
            if (ele.type === "Single") {
              //单选入参处理
              console.log('单选类型校验',typeof this.submitObject[item],this.submitObject[item])
              if(typeof this.submitObject[item] == 'string'){
                this.$set(submitObject[item], this.submitObject[item], true);
              }else{
                submitObject[item] = ''
              }
            } else if (ele.type === "Select") {
              //下拉入参处理
              console.log('下拉类型校验',typeof this.submitObject[item],this.submitObject[item])
              if(typeof this.submitObject[item] == 'string'){
                this.$set(submitObject[item], this.submitObject[item], true);
              }else{
                submitObject[item] = ''
              }
            } else if (ele.type === "Multi") {
              //多选入参处理
              console.log('多选入参处理',this.submitObject[item])
              if(this.submitObject[item].length>0){
                let singleArray = this.submitObject[item];
                singleArray.filter((e) => {
                console.log(e);
                this.$set(submitObject[item], e, true);
                });
              }else{
                submitObject[item] = []
              }
            } else if (ele.type === "FillBlank" || ele.type === "Score") {
              //单项填空、评分入参处理
              console.log('单项填空、评分入参处理',this.submitObject[item])
              if(this.submitObject[item].length>0){
                this.$set( submitObject[item],ele.children[0].id,this.submitObject[item]);
              }else{
                submitObject[item] = []
              }
            } else if (ele.type === "Cascader") {
              //级联选择入参处理
              console.log('级联选择入参处理',this.submitObject[item])
              if(this.submitObject[item].length>0){
                for (let i = 0; i < this.submitObject[item].length; i++) {
                this.$set(
                  submitObject[item],
                  ele.children[i].id,
                  this.submitObject[item][i]
                );
                }
              }else{
                submitObject[item] = []
              }
            } else if (ele.type === "MultipleBlank") {
              //多项填空入参处理
              console.log('多项填空入参处理',this.submitObject[item])
              if(this.submitObject[item].length>0){
                submitObject[item] = JSON.parse(JSON.stringify(this.submitObject[item]));
              }else{
                submitObject[item] = []
              }
            } else if (ele.type === "Upload") {
              //图片入参处理
              console.log('图片入参处理',this.submitObject[item])
              if(this.submitObject[item].length>0){
                let imageIdlist = [];
                this.attachment.filter((item) => {
                  imageIdlist.push(item.id);
                });
                this.$set(submitObject[item], ele.children[0].id, imageIdlist);
              }else{
                submitObject[item] = ''
              }
              
            }
          }
        });
      });
      console.log(submitObject);
      //转成入参格式----start>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   
      let data = {
        answer: {},
        attachment: [],
        shortId: "",
        userId: "",
        metaInfo: {
          answerInfo: {
            startTime: "",
            endTime: "",
          },
        },
        code:this.code,
      };
      data.answer = submitObject;
      data.attachment = this.attachment;
      data.shortId = this.shortId;
      data.userId = this.userId;
      data.metaInfo.answerInfo.startTime = this.startTime;
      data.metaInfo.answerInfo.endTime = this.endTime;
      console.log(this.userId);
      let lastUserId = localStorage.getItem("lastUserId");
      let lastTime = localStorage.getItem("lastTime");
      let timeNow = new Date().getTime()
      let IntervalTime = timeNow - lastTime
      console.log(lastUserId,this.userId,IntervalTime,lastTime,timeNow)
      if(lastUserId == this.userId && IntervalTime<86400000){
        this.$message({
          message: "请不要频繁提交问卷！",
          type: "error",
        });
        // this.leavePage()
        setTimeout(this.$router.push({
                path: "/h5.html",
        }),3000)
      }else{
        axios({
        method: "post",
        url: this.pagesUrl + "/api/public/saveAnswer",
        data: data,
      }).then((res) => {
        localStorage.setItem("lastUserId", this.userId);
        localStorage.setItem("lastTime", timeNow);
        console.log(res);
        this.$message({
          message: "提交成功，感谢您的反馈",
          type: "success",
        });
        // this.leavePage()
        this.$router.push({
                path: "/h5.html",
        });
      }).catch(err=>{
        this.$message({
          message: "服务异常提交失败",
          type: "success",
        });
      }).finally(()=>{
        this.loading = false
      })
      }
      
    },
    // 上传图片成功回调函数
    upSuccess(response, file, fileList) {
      this.attachment.push(JSON.parse(JSON.stringify(this.upImage)));
      let attachIndex = this.attachment.length - 1;
      console.log(this.attachment);
      this.attachment[attachIndex].id = response.data.attachmentId;
      this.attachment[attachIndex].originalName = response.data.fileName;
      this.attachment[attachIndex].contentType = file.raw.type;
      console.log(response);
      console.log(file);
      console.log(fileList);
    },
    // 填写验证
    requireFunc(e){
        let tempArray = []
        tempArray.push(e)
        return tempArray
    },
    leavePage(){
        let ua = navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i)=="micromessenger") {
            //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
            wx.miniProgram.getEnv((res)=>{
                if (res.miniprogram) {
                    // alert("在小程序里");
                    wx.switchTab({
                        url: '/pages/index/index'
                    })
                } else {
                    wx.switchTab({
                        url: '/pages/index/index'
                    })
                }
            })
        }else{
            this.$router.push({
                path: "/h5.html",
            });
        }
        // //判断是否在ios端
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //         setTimeout(_=>{
        //         // if(document.getElementsByTagName('html').classList.contains('minsoft')){
        //             let html = document.getElementsByTagName('html')[0]
        //         if(html.className == 'minsoft'){
        //             // console.log(html.className) 
        //             // this.$edd(html.className) 
        //             // this.$edd(...document.getElementsByTagName('html')) 
        //             // this.$forceUpdate()

        //             // this.$router.replace('/my')
        //             // setTimeout(_=>{
        //             //   this.$router.replace('/home')
        //             // },50)
        //         }
        //         },50)
        //     }

    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
}
.imgBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.maxImg {
  width: 100%;
  height: 100%;
}
.minImg {
  position: absolute;
  width: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
}
.minImg_pc {
  position: absolute;
  left: 20%;
  transform: translate(-50%, -50%);
  bottom: -10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.minImg_pc_right {
  position: absolute;
  right: 10%;
  transform: translate(-50%, -50%);
  bottom: -10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonWords {
  border: none;
  width: 100%;
  border-radius: unset;
  font-weight: 500;
  font-size: 16px;
  color: black;
  font-family: fantasy;
}
.form {
  width: 700px;
  margin: 100px auto;
}
.mobileform {
  width: 90%;
  margin: 12px auto;
}
/deep/.el-form-item__label {
  width: 100%;
  text-align: left;
}
.multipleBlank {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wancheng {
  margin: 12px auto;
}
.title {
    display: inline-block;
    width: 100%;
    text-align: center;
}
</style>
